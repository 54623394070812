import { graphql, useFragment } from 'react-relay/hooks';
import { SanityPage } from '@pafcloud/sanity-blocks';
import type { PageWithQueryData } from '@pafcloud/with-data';
import { PageContainer } from '@pafcloud/base-components';
import { PageSeoText } from '@pafcloud/block-components';
import type { SlugPageQuery } from './__generated__/SlugPageQuery.graphql';
import type { SlugPage_pageData$key } from './__generated__/SlugPage_pageData.graphql';
import { getPageSlug } from './getPageSlug';
import { NotFoundPage } from './NotFoundPage';

const pageDataFragment = graphql`
  fragment SlugPage_pageData on Page {
    ...SanityPage_page
    ...PageSeoText_data
  }
`;

export const SlugPage: PageWithQueryData<SlugPageQuery> = (props) => {
  const page = useFragment<SlugPage_pageData$key>(pageDataFragment, props.pageData.page);

  if (page == null) {
    return <NotFoundPage />;
  }

  return (
    <>
      <PageContainer>
        <SanityPage page={page} />
      </PageContainer>

      <PageSeoText data={page} />
    </>
  );
};

SlugPage.query = graphql`
  query SlugPageQuery($slug: String!) {
    ...App_data
    page(slug: $slug) {
      ...SlugPage_pageData
    }
  }
`;

SlugPage.getInitialProps = (ctx) => ({
  queryArguments: {
    slug: getPageSlug(ctx) ?? '',
  },
  postQuery: (data) => {
    if (ctx.res && data.page == null) {
      ctx.res.statusCode = 404;
    }
  },
});
