/**
 * @generated SignedSource<<870438e17d6995c57e980d2a1a43a4f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SlugPage_pageData$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PageSeoText_data" | "SanityPage_page">;
  readonly " $fragmentType": "SlugPage_pageData";
};
export type SlugPage_pageData$key = {
  readonly " $data"?: SlugPage_pageData$data;
  readonly " $fragmentSpreads": FragmentRefs<"SlugPage_pageData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SlugPage_pageData",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SanityPage_page"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PageSeoText_data"
    }
  ],
  "type": "Page",
  "abstractKey": null
};

(node as any).hash = "661ebc6503b44331bd4e7fa3056c6cc7";

export default node;
